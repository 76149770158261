<template lang="">
    <div>
      <h6 class="heading-small text-muted mb-4">ข้อมูลซัพพลายเออร์</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label">ชื่อบริษัท/ชื่อร้าน</label>
                  <base-input name="name" placeholder="ชื่อบริษัท/ชื่อร้าน" v-model="item.name" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">หมายเลขผู้เสีภาษี</label>
                  <base-input name="tax_id" placeholder="หมายเลขผู้เสีภาษี" v-model="item.tax_id"></base-input>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">เกรด</label>
                  <base-input name="tier" placeholder="เกรด" v-model="item.tier"></base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="mb-4">
              <label class="form-label">เบอร์โทรศัพท์</label>
              <base-input name="main_phone" placeholder="เบอร์โทรศัพท์" v-model="item.main_phone" :rules="{required: true}" v-number-only></base-input>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label">ที่อยู่ตามใบเสร็จรับเงิน</label>
              <b-form-textarea name="tax_address" placeholder="ที่อยู่ตามใบเสร็จรับเงิน" v-model="item.tax_address" rows="3" max-rows="5" :rules="{required: true}"></b-form-textarea>
              <base-input name="tax_address" v-model="item.tax_address" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
            </div>
          </div>

          <div class="col-sm-12">
            <hr class="my-4">
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">ชื่อผู้ติดต่อ</label>
                  <!-- <b-form-tags  input-id="tags-basic" v-model="item.contactArr" tag-class="tags_align_center" placeholder="ชื่อผู้ติดต่อ"
                  name="contact" autocomplete="off" remove-on-delete></b-form-tags> -->
                  <!-- <base-input name="contact" v-model="contact" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input> -->
                  <base-input name="contact" placeholder="ชื่อผู้ติดต่อ" v-model="item.contact" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">เบอร์โทรศัพท์</label>
                  <base-input name="phone" placeholder="เบอร์โทรศัพท์" v-model="item.phone" :rules="{required: true}" v-number-only></base-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">อีเมล</label>
                  <base-input type="email" name="email" placeholder="อีเมล" v-model="item.email" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">ไลน์</label>
                  <base-input name="line" placeholder="ไลน์" v-model="item.line" :rules="{required: true}"></base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label optional">หมายเหตุ</label>
              <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label optional">ที่อยู่จัดส่ง</label>
              <b-form-textarea name="full_address" placeholder="ที่อยู่จัดส่ง" v-model="item.full_address" rows="3" max-rows="5"></b-form-textarea>
              <base-input name="full_address" :rules="{required: true}" v-model="item.full_address" class="custom-input-valid-datepicker"></base-input>
            </div>
          </div>-->

          <div class="col-sm-12">
            <hr class="my-4">
          </div>

          <!-- <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label optional">หมายเหตุ</label>
              <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
            </div>
          </div> -->
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'supplier-view-form-supplier',
  data(){
    return {
      contact: ''
    };
  },
  props: [
    'item'
  ],
  methods: {},
  watch:{
    "item.contactArr" (newValue, oldValue){
      if(newValue.length>0){
        this.contact = 'a';
      }else{
        this.contact = '';
      }
    }
  }
}
</script>
